.form, #root, body, html, .uppy {
  width: 100%;
  height: 100%;
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

.form {
    background-color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form form {
    display: block;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    max-width: 100%;
    width: 400px;
}

.form form label {
    display: block;
}

.form form input[type=password] {
    display: block;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #444;
    padding: 5px 10px;
}

.form form input[type=submit] {
    color: #fff;
    background-color: cornflowerblue;
    border: 1px solid cornflowerblue;
    border-radius: 5px;
    padding: 5px 10px;
    margin-top: 10px;
    width: 100%;
}

.uppy {
    width: 100%;
    background-color: #333;
}

.uppy div:not(.history) .uppy-Dashboard-inner {
    margin: 0 auto;
}

.uppy .history {
    width: 750px;
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
    display: block;

    color: white;
}

a {
    color: inherit;
}